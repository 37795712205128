import React from 'react';
import { Link } from 'gatsby';

class VenuesSection extends React.Component {
  render() {
    const { venuesSection } = this.props

    return (
      <div className="container sm-w85 pt-5 pb-4 bordered-top take-a-pick-section">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="red mb30">{venuesSection.sectionTitle}</h1>
            <p>
              {venuesSection.sectionCaption}
            </p>
          </div>
        </div>

        <div className="row takepick">
          <div className="col-md-4 text-center mt-5">
            <img src={venuesSection.locationImage1.file.url} alt="" />
            <h3 class="red">{venuesSection.locationName1}</h3>
            <div className="row action-btns mt30 mb-3 pb-3 justify-content-center">
              <Link to="/venues">
                <button className="btn">{venuesSection.locationBtn1}</button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 text-center mt-5">
            <img src={venuesSection.locationImage2.file.url} alt="" />
            <h3 class="red">{venuesSection.locationName2}</h3>
            <div className="row action-btns mt30 mb-3 pb-3 justify-content-center">
              <Link to="/venues/museum">
                <button className="btn">{venuesSection.locationBtn2}</button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 text-center mt-5">
            <img src={venuesSection.locationImage3.file.url} alt="" />
            <h3 class="red">{venuesSection.locationName3}</h3>
            <div className="row action-btns mt30 mb-3 pb-3 justify-content-center">
              <Link to="/venues/rooms/los-tamaraos">
                <button className="btn">{venuesSection.locationBtn3}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VenuesSection;
